import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Tdwb6kXmqsI">
    <SEO title="The Anthem of Life - Greatest Hits" />
  </Layout>
)

export default SermonPost
